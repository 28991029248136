import * as Sentry from '@sentry/vue'
<template>
    <add-write-off ref="editWriteOff" :view="view"/>
</template>

<script>
    import AddWriteOff from '@/views/WriteOff/AddWriteOff.vue'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {
            AddWriteOff
        },
        data() {
            return {
                writeOff: null,
                writeOffTemporary: null,
                writeOffId: '',
                view: false,
                editing: false
            }
        },
        methods:{
            async loadDataEditing() {
                try {
                    const res1 = this.$http.get(`/api/client/v1/write_offs_temporary/${this.writeOffId}/`)
                    const res2 = this.$http.get(`/api/client/v1/write_offs/${this.writeOffId}/`)

                    const responses = await Promise.all([res1, res2])
                    this.writeOff = responses[1].data.write_off ?? {}
                    this.writeOffTemporary = responses[0].data ?? {}
                    this.editing = responses[1].data.exists

                    if (Object.keys(this.writeOff).length !== 0) {
                        this.writeOff.timestamp = this.dayjs(this.writeOff.timestamp).toISOString()
                    }
                    
                    this.writeOffTemporary.timestamp = this.dayjs(this.writeOffTemporary.timestamp).toISOString()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                }
            }
        },
        async mounted() {
            this.writeOffId = this.$route.params.id
            await this.loadDataEditing()
            await this.$refs.editWriteOff.openEditing(this.writeOffTemporary, this.writeOff, this.editing)
        }
    }
</script>